<template>
  <dialog-form
    :dialog="dialog"
    :form-title="$t('domains.providers.domains.gen.dialog.title')"
    :form-scheme="domainsGeneratorFormScheme"
    :save-loading="domainsGeneratorSaveLoading"
    :edited-item="domainsGeneratorEditedItem"
    :submit-text="$t('domains.providers.domains.gen.dialog.generate')"
    @changeEditedItem="changeEditedItem"
    @save="domainsGeneratorAction"
    @close="domainsGeneratorDialogClose"
  />
</template>

<script>
export default {
  name: 'DomainsGenerator',
  props: {
    dialog: Boolean,
    advanced: {
      type: Boolean,
      default: false
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    domainsGeneratorFormScheme: {},
    domainsGeneratorSaveLoading: false,
    domainsGeneratorEditedItem: {}
  }),
  watch: {
    dialog() {
      this.domainsGeneratorEditedItem = Object.assign({}, this.editedItem)
    },
    'domainsGeneratorEditedItem.prefix'(val) {
      if (this.domainsGeneratorEditedItem.prefix.length > 0) {
        this.domainsGeneratorFormScheme.prefix_type.disabled = true
        this.domainsGeneratorFormScheme.prefix_len.disabled = true
      }
    },
    'domainsGeneratorEditedItem.suffix'(val) {
      if (this.domainsGeneratorEditedItem.suffix.length > 0) {
        this.domainsGeneratorFormScheme.suffix_type.disabled = true
        this.domainsGeneratorFormScheme.suffix_len.disabled = true
      }
    }
  },
  async mounted () {
    this.domainsGeneratorEditedItem = Object.assign({}, this.editedItem)

    this.domainsGeneratorFormScheme = {
      keywords: {
        type: 'text',
        ref: 'keywords',
        label: this.$t('domains.providers.domains.gen.dialog.keywords'),
        createRules: 'req',
        updateRules: [],
        md: 4
      }
    }
    this.domainsGeneratorFormScheme.tlds = {
      type: 'select',
      items: await this.$store.dispatch('domainsGenerator/getTldsList') || [],
      multiple: true,
      label: 'TLD`s',
      createRules: 'req',
      updateRules: [],
      md: 4
    }
    this.domainsGeneratorFormScheme.quantity = {
      type: 'number',
      label: this.$t('domains.providers.domains.gen.dialog.quantity'),
      createRules: 'req',
      updateRules: [],
      md: 4
    }

    if (this.advanced === true) {
      this.domainsGeneratorFormScheme.keywords.md = 6
      this.domainsGeneratorFormScheme.tlds.md = 6
      this.domainsGeneratorFormScheme.words = {
        type: 'number',
        label: this.$t('domains.providers.domains.gen.dialog.words'),
        createRules: 'req',
        updateRules: [],
        clearable: undefined,
        md: 4
      }
      this.domainsGeneratorFormScheme.separator = {
        clearable: false,
        type: 'select',
        label: this.$t('domains.providers.domains.gen.dialog.separator'),
        items: [
          { text: '—', value: '-' },
          { text: 'none', value: '' }
        ],
        createRules: [],
        updateRules: [],
        md: 4
      }
      this.domainsGeneratorFormScheme.prefixState = {
        type: 'switch',
        md: 1,
        fn: (val) => {
          this.domainsGeneratorFormScheme.prefix.disabled = !this.domainsGeneratorEditedItem.prefixState
          this.domainsGeneratorFormScheme.prefix_type.disabled = !this.domainsGeneratorEditedItem.prefixState
          this.domainsGeneratorFormScheme.prefix_len.disabled = !this.domainsGeneratorEditedItem.prefixState
        }
      }
      this.domainsGeneratorFormScheme.prefix = {
        type: 'text',
        ref: 'keywords',
        label: this.$t('domains.providers.domains.gen.dialog.prefix'),
        disabled: true,
        md: 5
      }
      this.domainsGeneratorFormScheme.prefix_type = {
        clearable: true,
        type: 'select',
        label: this.$t('domains.providers.domains.gen.dialog.prefixType'),
        items: [
          { text: this.$t('domains.providers.domains.gen.dialog.prefixTypeString'), value: 'string' },
          { text: this.$t('domains.providers.domains.gen.dialog.prefixTypeNumber'), value: 'number' },
          { text: this.$t('domains.providers.domains.gen.dialog.prefixTypeMixed'), value: 'mixed' }
        ],
        createRules: [],
        updateRules: [],
        disabled: true,
        md: 3
      }
      this.domainsGeneratorFormScheme.prefix_len = {
        type: 'number',
        label: this.$t('domains.providers.domains.gen.dialog.prefixLen'),
        createRules: [],
        updateRules: [],
        disabled: true,
        md: 3
      }
      this.domainsGeneratorFormScheme.suffixState = {
        type: 'switch',
        md: 1,
        fn: (val) => {
          this.domainsGeneratorFormScheme.suffix.disabled = !this.domainsGeneratorEditedItem.suffixState
          this.domainsGeneratorFormScheme.suffix_type.disabled = !this.domainsGeneratorEditedItem.suffixState
          this.domainsGeneratorFormScheme.suffix_len.disabled = !this.domainsGeneratorEditedItem.suffixState
        }
      }
      this.domainsGeneratorFormScheme.suffix = {
        type: 'text',
        ref: 'keywords',
        label: this.$t('domains.providers.domains.gen.dialog.suffix'),
        disabled: true,
        md: 5
      }
      this.domainsGeneratorFormScheme.suffix_type = {
        clearable: true,
        type: 'select',
        label: this.$t('domains.providers.domains.gen.dialog.suffixType'),
        items: [
          { text: this.$t('domains.providers.domains.gen.dialog.suffixTypeString'), value: 'string' },
          { text: this.$t('domains.providers.domains.gen.dialog.suffixTypeNumber'), value: 'number' },
          { text: this.$t('domains.providers.domains.gen.dialog.suffixTypeMixed'), value: 'mixed' }
        ],
        createRules: [],
        updateRules: [],
        disabled: true,
        md: 3
      }
      this.domainsGeneratorFormScheme.suffix_len = {
        type: 'number',
        label: this.$t('domains.providers.domains.gen.dialog.suffixLen'),
        createRules: [],
        updateRules: [],
        disabled: true,
        md: 3
      }
    }
  },
  methods: {
    changeEditedItem(changes) {
      this.domainsGeneratorEditedItem = changes
      return changes
    },
    async domainsGeneratorAction(e) {
      this.domainsGeneratorSaveLoading = true
      try {
        const v = e.$refs.form.validate()
        if (v) {
          // eslint-disable-next-line camelcase
          const { keywords, quantity, tlds, words, separator, prefix, prefix_type, prefix_len, suffix, suffix_type, suffix_len } = this.domainsGeneratorEditedItem
          if (!keywords) {
            return this.$toast.error(this.$t('domains.providers.domains.gen.dialog.keywordEmpty'))
          }
          const response = await this.$store.dispatch('domainsGenerator/quickGenerate', {
            data: {
              keywords,
              words,
              quantity,
              tlds,
              separator,
              prefix,
              prefix_type,
              prefix_len,
              suffix,
              suffix_type,
              suffix_len
            }
          })
          if (response.success === true) {
            this.$emit('result', response.result)
            await this.$toast.success(this.$t('domains.providers.domains.gen.dialog.success'))
            this.domainsGeneratorDialogClose()
          } else {
            await this.$toast.error(response.errors)
          }
        }
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      } finally {
        this.domainsGeneratorSaveLoading = false
      }
    },
    domainsGeneratorDialogClose() {
      this.$emit('close', this)
    },
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('domains.providers.tableForm.validation.fieldIsRequired')
      }
      return !!value || this.$t('domains.tableForm.validation.fieldIsRequired')
    }
  }
}
</script>

<style scoped>

</style>

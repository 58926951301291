<template>
  <div>
    <domains-generator
      :dialog="domainsGeneratorDialog"
      :edited-item="domainsGeneratorEditedItem"
      @close="domainsGeneratorDialogClose"
      @result="domainsGeneratorResult"
    />
    <dialog-form
      :dialog="dialog"
      :form-title="$t('domains.providers.domains.dialog.title')"
      :form-scheme="domainsRegisterFormScheme"
      :save-loading="domainsRegisterSaveLoading"
      :edited-item="domainsRegisterEditedItem"
      :submit-text="$t('domains.providers.domains.dialog.add')"
      @changeEditedItem="changeEditedItem"
      @save="registerDomains"
      @close="domainsRegisterDialogClose"
    />
  </div>
</template>

<script>
import DomainsGenerator from './DomainsGenerator'
import Vue from 'vue'
import numWords from '../../../../utils/numWords'

export default {
  name: 'DomainsRegister',
  components: {
    DomainsGenerator
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    editedItem: {
      type: Object,
      default: () => {
      }
    },
    providerSelection: {
      type: Boolean,
      default: false
    },
    affiliateSelection: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // DomainsRegister
    domainsRegisterFormScheme: {},
    domainsRegisterSaveLoading: false,
    domainsRegisterEditedItem: {},
    // DomainsGenerator
    domainsGeneratorDialog: false,
    domainsGeneratorEditedItem: {}
  }),
  watch: {
    dialog(val) {
      if (val === true) {
        Vue.set(this.domainsRegisterEditedItem, 'domain_provider_id', this.editedItem.id)
      }
    }
  },
  async mounted () {
    if (this.affiliateSelection === true) {
      this.domainsRegisterFormScheme.affiliate_id = {
        type: 'autocomplete',
        label: this.$t('tasks.generator.tableForm.affiliate'),
        items: await this.$store.dispatch('affiliates/all'),
        createRules: 'req', // v => !!v || this.$t('tasks.generator.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 12
      }
    }
    if (this.providerSelection === true) {
      this.domainsRegisterFormScheme.domain_provider_id = {
        type: 'select',
        items: await this.$store.dispatch('domainsProviders/getDomainsProvidersList') || [],
        label: this.$t('Domains provider list'),
        createRules: 'req',
        updateRules: [],
        md: 12
      }
    }
    this.domainsRegisterFormScheme.domains = {
      type: 'combobox',
      label: this.$t('domains.providers.domains.dialog.domain'),
      createRules: 'req',
      updateRules: [],
      chips: true,
      multiple: true,
      // rows: 1,
      // 'auto-grow': true,
      md: 12,
      hint: this.$t('domains.providers.domains.dialog.hint'),
      appendIcon: 'brain',
      appendCopyBtn: true,
      appendClickFn: async (e, t) => this.domainGeneratorDialogOpen(e, t),
      clearable: true
    }
  },
  methods: {
    changeEditedItem(changes) {
      if (changes.domains instanceof Array && typeof changes.domains[0] === 'string') {
        if (/[\s,]/.test(changes.domains[0])) {
          changes.domains = changes.domains[0].split(/[\s,]/)
        }
      }
      return changes
    },
    async registerDomains(e) {
      try {
        this.domainsRegisterSaveLoading = true
        const v = e.$refs.form.validate()
        if (v) {
          let taskName = `${this.$t('domains.providers.domains.dialog.nameTemplate', { domainsNum: numWords(this.domainsRegisterEditedItem.domains.length) })}`
          let affiliateNameWithTeam
          if (this.affiliateSelection === true) {
            const affiliates = await this.$store.dispatch('affiliates/all')
            const affiliate = affiliates.find(aff => aff.value === this.domainsRegisterEditedItem.affiliate_id)
            if (affiliate) {
              this.domainsRegisterEditedItem.affiliate_id = affiliate.value
              affiliateNameWithTeam = affiliate.text
            }
            taskName += ` for "${affiliateNameWithTeam}"`
          }
          Object.assign(this.domainsRegisterEditedItem, { name: taskName })
          const response = await this.$store.dispatch('tasksSdr/addTask', this.domainsRegisterEditedItem)

          this.domainsRegisterDialogClose()
          await this.$toast.success(this.$t('domains.providers.domains.dialog.success'))
          await this.$router.push({ name: 'tasksSDRDetails', params: { id: response.id } })

          Vue.prototype.$refreshData.updateRecordInResourceTable('tasks.sdr', response)
        }
      } catch (e) {
        console.log(e)
        await this.$toast.error(e.response.data.message || e.message)
      } finally {
        this.domainsRegisterSaveLoading = false
      }
    },
    domainsRegisterDialogClose() {
      this.$emit('close', this)
    },
    domainsGeneratorResult(result) {
      Vue.set(this.domainsRegisterEditedItem, 'domains', ...result)
    },
    /* ---------------------------------------------------------------- */
    // GENERATOR
    /* ---------------------------------------------------------------- */
    domainGeneratorDialogOpen() {
      this.domainsGeneratorEditedItem = Object.assign({}, { quantity: 1, keyword: '', tlds: ['com'] })
      this.domainsGeneratorDialog = true
    },
    domainsGeneratorDialogClose() {
      this.domainsGeneratorDialog = false
      this.domainsGeneratorEditedItem = {}
    },
    /* ---------------------------------------------------------------- */
    // VALIDATION
    /* ---------------------------------------------------------------- */
    required(value) {
      if (value instanceof Array && value.length === 0) {
        return this.$t('domains.providers.tableForm.validation.fieldIsRequired')
      }
      return !!value || this.$t('domains.tableForm.validation.fieldIsRequired')
    }
  }
}
</script>

<style scoped>

</style>
